
$txt-color: #B9B9BF;
$white: #fff;
$color-hover: #BEBBB6;
$color-st2 :#D6D0E8;
$font-main: "KronaOne-Regular";
$font-main2: "KronaOne-Regular";
$bg-main: #18191a;
$header-height: 100px;
$bgr-body :#020204;
$bg-2 :#BEBBB6;
$bg-3 :#18191a;
$bg-4 :#18191a;
$bg-5 :#282a2c;

$border-width: 2px;
$corner-size: 3rem;
$font-w6:600;
$font-w7:700;
$color-main1 :#B9B9BF; 
$color-main2 :#565660; 
$color-3 :#464138; 
$w100:100%;
$mb-30: 30px;
$mg32: 32px;
$mb-40: 40px;
$ml-30: -30px;
$center: center;

$padding-right:18px;

$box-shadow: 0 0px 32px 0 #BEBBB6;

$boder-bt: 1px solid #ffffff33;
$bg-gradient: linear-gradient(to bottom, #F7F7F5 10%, #E9E9E9 100%);
$bg-gradient2: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.13%, #161616 100%);
$bg-gradient3: linear-gradient(90deg, rgba(0, 0, 0, 0) 0.13%, #161616 100%);
$res_1600: 1600px;
$res_1440: 1440px;
$res_1200: 1200px;
$res_991: 991px;
$res_767: 767px;
$res_600: 600px;
$res_500: 500px;
$res_400: 500px;

$mobile-width: 767px;
$tablet-width: 1024px;
$desktop-width: 1200px;
$desktop-1350: 1350px;
$desktop-1500: 1500px;
$desktop-1900 : 1900px;

