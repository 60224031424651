@use '../../scss/' as *;

.circle-container{
    z-index: 2;
    margin-bottom: -2%;
}

.progress-bar-fill{
    color: transparent;
    background-color: #FFF;
    transition: width .6s ease;
    height: 4px;
}

.circle{
    width: 20%;
}
.circ-ins{
    background-color: #B9B9BF;;
    width: 10%;
    border-radius: 50%;
}

.bar-fill-container{
    height: 28px;

}